<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="card card-default animated-fast fadeInLeft">
                <div class="card-body" :class="loading ? 'whirl' : ''">
                    <form-wizard @on-complete="sendMessage">
                        <template slot="step" slot-scope="props">
                            <!-- By using a custom header markup we can pass html in title attribute -->
                            <wizard-step
                                    :tab="props.tab"
                                    @click.native="props.navigateToTab(props.index)"
                                    @keyup.enter.native="props.navigateToTab(props.index)"
                                    :transition="props.transition"
                                    :index="props.index">
                                <span slot="title" :class="{'text-danger':props.tab.validationError}" v-html="props.tab.title"></span>
                            </wizard-step>
                        </template>
                        <!-- Headers -->
                        <tab-content :title="$t('support.view.contact.form.info')">

                          <div v-if="$route.params.question">
                            <p>{{ $t('support.view.contact.input_label') }}:</p>
                            <b-input type="text" class="p-4 mb-3" :value="$route.params.question" disabled/>
                          </div>

                            <h4 class="mt-3">{{$t('support.view.contact.type')}}</h4>

                            <div class="list-group mt-4">
                                <a v-for="(form, formName) in forms" @click.prevent="selectForm(formName)" :class="{active: selectedFormName === formName}" href=""
                                   class="list-group-item list-group-item-action">
                                    <h4 class="mb-1">{{ form.title }}</h4>
                                    <p class="mb-1">{{ form.description }}</p>
                                </a>
                            </div>
                        </tab-content>
                        <tab-content :title="$t('support.view.contact.form.info_message')">
                            <b-row>
                                <b-col md="12" lg="4" offset-lg="1">
                                    <h4 class="mt-3">{{ selectedForm.title }}</h4>
                                    <p class="mt-3">{{ selectedForm.description }}</p>
                                    <hr/>
                                    {{$t('support.view.contact.message.info')}}

                                </b-col>
                                <b-col md="12" lg="6">
                                  <div v-if="selectedFormName === '99-shop'">

                                    <div class="mb-2">{{ $t('support.view.contact.forms.99-shop.hint') }}</div>

                                    <b-btn class="mb-1" :href="$t('support.view.contact.forms.99-shop.button.eu.link')" target="_blank" variant="primary"> <span class="flag-icon flag-icon-eu mr-1 "></span> {{ $t('support.view.contact.forms.99-shop.button.eu.label') }}</b-btn>
                                    <b-btn  :href="$t('support.view.contact.forms.99-shop.button.ch.link')" target="_blank" variant="primary"> <span class="flag-icon flag-icon-ch mr-1"></span> {{ $t('support.view.contact.forms.99-shop.button.ch.label') }}</b-btn>

                                    <hr/>
                                  </div>
                                    <b-form-group class="p-0">
                                        <label>{{$t('support.view.contact.message.subject.label')}}</label>
                                        <b-input v-model="formFields.title" required :placeholder="$t('support.view.contact.message.subject.label')"></b-input>
                                    </b-form-group>
                                    <b-form-group class="p-0">
                                        <label>{{$t('support.view.contact.message.language.label')}}</label>
                                        <b-form-select v-model="formFields.language">
                                            <b-form-select-option value="de">Deutsch</b-form-select-option>
                                            <b-form-select-option value="en">English</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group>
                                        <label>{{$t('support.view.contact.message.message.label')}}</label>
                                        <b-textarea style="height: 300px" required v-model="formFields.message" v-on:keydown="keydown()"
                                                    :placeholder="$t('support.view.contact.message.message.label')"></b-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <faq-query ref="query" :title="formFields.title" :message="formFields.message" :language="formFields.language"></faq-query>

                            <div class="text-right" v-if="formFields.message && !$refs.query.loading" >
                              <div class="inline" style="max-width:500px;">Wir haben erneut für sie nach einer Antwort gesucht, falls sie keine Lösung finden können sie uns nun Ihre nachricht übermitteln!</div>
                            </div>

                        </tab-content>

                        <!-- Directions -->
                        <b-btn variant="secondary" slot="prev">{{$t('support.view.contact.directions.back')}}</b-btn>
                        <b-btn variant="secondary" :disabled="!selectedFormName" slot="next">{{$t('support.view.contact.directions.next')}}</b-btn>
                        <b-btn variant="info" :disabled="waitingForKeys" slot="finish">{{$t('support.view.contact.directions.finish')}}</b-btn>
                    </form-wizard>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style lang="scss">
    .markdown-body {

        &:before, &:after {
            display: none;
        }

        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        color: inherit;

        a {
            color: inherit;
        }
    }
</style>
<script>
    import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'
    import 'flag-icon-css/css/flag-icons.min.css';
    import FaqQuery from "../components/FaqQuery";

    export default {
        data() {
            return {
                loading: false,
                waitingForKeys: false,
                selectedFormName: '',
                selectedForm: {
                  title: '',
                  description: '',
                },
                forms: this.$i18n.t('support.view.contact.forms'),
                answers: [],
                confirmNoAnswer: false,
                keyDownTimeout: null
            }
        },
        components: {
          FaqQuery,
            FormWizard,
            TabContent,
            WizardStep
        },
        created() {
            this.formFields.language = this.$i18n.locale;
        },
      beforeMount() {
        this.formFields.title = this.$route.params.question;
      },
        methods: {

            keydown() {
                this.waitingForKeys = true;
                this.$refs.query.triggerLoading();
                clearTimeout(this.keyDownTimeout);
                this.keyDownTimeout = setTimeout(() => {
                  this.loading = true;
                  this.$refs.query.searchAnswer().then(() => {
                    this.waitingForKeys = false;
                    this.loading = false;
                  })
                }, 3500)
            },
            sendMessage() {

                if (!this.formFields.title || !this.formFields.message) {
                    this.$swal.fire('Form invalid', 'Please complete the form');
                    return false;
                }

                this.loading = true;
                this.$api.post('support/tickets', {
                    title: this.formFields.title,
                    body: this.formFields.message,
                    tag: this.selectedForm.tag,
                }).then((response) => {
                    this.loading = false;
                    this.$swal.fire('Message sent, we also sent you an copy to your email', '', 'success');
                    this.$router.push({name: 'Support:Ticket', params: {id: response.data.ticket_id}});
                }).catch(() => {
                    this.loading = false;
                    this.$swal.fire('There was an error while submitting your form', '', 'error');
                });
            },
            selectForm(formName) {
                this.selectedFormName = formName;
                this.selectedForm = this.forms[formName];
            }
        }
    }
</script>

<style src="../../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css"></style>
