<template>

  <div :class="{whirl: loading}">

    <div v-if="!questions.length && loading" style="min-height:100px"></div>

    <div class="mb-4" v-if="questions.length">

      <h4>{{ $t('support.view.contact.message.hint') }}</h4>

      <accordion-card v-for="(entry, index) in questions" @opened="opened(entry)" :key="'index' + index">
        <template v-slot:title>
          {{ entry.question }}<br/>
            <span class="text-gray">
                <small class="ml-4">Zuletzt aktualisiert <b>{{$moment.utc(entry.updated_at).fromNow()}}</b></small><span class="float-right" v-if="entry.recommends"><small>Wurde <b>{{ entry.recommends }}x</b> als hilfreich bewertet</small></span>
            </span>
        </template>

        <markdown-parser :horizontal-line="false" :highlight="false" :table="false"
                         :source="entry.answer"></markdown-parser>
        <div>
          <div class="text-right mt-2" v-if="!entry.rated">
            <small>{{ $t('support.view.contact.feedback.title') }}</small><br/>
            <b-btn @click="giveFeedback(entry, 'question_found_but_no_answer')" class="mr-2">
              {{ $t('support.view.contact.feedback.question_found_but_no_answer') }}
            </b-btn>
            <b-btn variant="primary" @click="giveFeedback(entry, 'helped')">{{
                $t('support.view.contact.feedback.helped')
              }}
            </b-btn>
          </div>
        </div>
      </accordion-card>
    </div>

  </div>
</template>

<script>


import AccordionCard from "./AccordionCard";
import MarkdownParser from "../../../components/Common/MarkdownParser";

export default {
  props: [
    'title',
    'message',
    'language',
  ],
  components: {
    AccordionCard,
    MarkdownParser
  },
  data() {
    return {
      questions: [],
      loading: false
    };
  },
  mounted() {

  },
  methods: {
    triggerLoading() {
      this.loading = true;
    },
    opened(entry) {
      if (!entry.opened) {
        this.$api.post('support/question/' + entry.id + '/opened');
        entry.opened = true;
      }
    },
    searchAnswer() {
      this.loading = true;
      return this.$api.post('support/categories/search-similar-questions', {
        question: this.title,
        message: this.message,
        language: this.language
      }).then(response => {

        if (window._paq) {
          window._paq.push(['trackSiteSearch', this.title, 'FAQ', response.data.questions.length]);
        }

        this.loading = false;
        this.questions = response.data.questions;
        this.confirmNoAnswer = this.questions.length === 0;
      })
    },
    giveFeedback(entry, rating) {
      this.$set(entry, 'rated', true);
      this.$api.post('support/give-feedback', {
        question: this.title,
        message: this.message,
        language: this.language,
        rating: rating,
        question_id: entry.id
      })
    },
  }
}
</script>
